import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  setLabels,
  useAddLabelsMutation,
  useRemoveLabelsMutation,
} from "../core";
import { RootState } from "../store";
import styled from "styled-components";
import { GithubPicker } from "react-color";
import { useUpdateLabelMutation } from "../core/apis/labels";

export function Navigation() {
  const [color, setColor] = useState("");
  const [label, setLabel] = useState("");
  const [pickersVisibility, setPickersVisibility] = useState([]);
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const labels = useSelector((state: RootState) => state.labels);
  const [addLabel] = useAddLabelsMutation();
  const [removeLabel] = useRemoveLabelsMutation();
  const [updateLabel] = useUpdateLabelMutation();
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

  return (
    <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
      <li className="pb-4">
        <NavLink to="/">Home</NavLink>
      </li>
      <li className="mb-4">
        <NavLink to="/tasks">Tasks</NavLink>
        <ul
          className={`mt-4 ${
            location.pathname.includes("tasks") ? "" : "hidden"
          }`}
        >
          {!labels?.length
            ? ""
            : labels.map((label: any, index: number) => (
                <div className="flex gap-2">
                  <ColorPickerToggle
                    className="h-5 w-5 top-1 bg-white rounded"
                    color={label.color}
                    onClick={() => {
                      if (pickersVisibility[index]) {
                        (pickersVisibility[index] as boolean) = false;
                      } else {
                        (pickersVisibility[index] as boolean) = true;
                      }
                      setPickersVisibility([...pickersVisibility]);
                    }}
                  >
                    <ColorPickerContent
                      className={`${pickersVisibility[index] ? "" : "hidden"}`}
                    >
                      <GithubPicker
                        className="hidden"
                        color={label.color}
                        onChangeComplete={(color) => {
                          updateLabel({
                            id: user?.uuid,
                            label: { ...label, color: color.hex },
                          });
                        }}
                      />
                    </ColorPickerContent>
                  </ColorPickerToggle>

                  <Label
                    color={label.color}
                    key={label.uuid}
                    className="bg-red-100 flex-1 text-white relative rounded-box p-1 pl-4 mb-4"
                  >
                    {label.name}
                    <span
                      onClick={() => {
                        removeLabel({ id: user?.uuid, label: label.uuid });
                      }}
                      className="absolute right-0 -bottom-1 hover:text-black"
                    >
                      x
                    </span>
                  </Label>
                </div>
              ))}
        </ul>
        <div
          className={`flex pr-0 ${
            location.pathname.includes("tasks") ? "" : "hidden"
          }`}
        >
          <input
            className="input input-sm w-full"
            placeholder="Add new label..."
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
            type="text"
          />{" "}
          <ColorPickerToggle
            className="h-6 w-8 bg-white rounded"
            color={color}
            onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
          >
            <ColorPickerContent
              className={`${isColorPickerVisible ? "" : "hidden"}`}
            >
              <GithubPicker
                className="hidden"
                color={color}
                onChangeComplete={(color) => {
                  setColor(color.hex);
                }}
              />
            </ColorPickerContent>
          </ColorPickerToggle>
          <button
            className="btn btn-primary btn-sm"
            onClick={async () => {
              await addLabel({
                id: user?.uuid,
                label: {
                  color: color,
                  name: label,
                },
              });

              setLabel("");
            }}
          >
            Add
          </button>
        </div>
      </li>
      <li className="pb-4">
        <NavLink to="/habits">Habits</NavLink>
      </li>
    </ul>
  );
}

const Label = styled.li`
  background-color: ${(props) => props.color || "#1273de"};
`;

const ColorPickerToggle = styled.button`
  background-color: ${(props) => props.color || "#1273de"};
  position: relative;
`;

const ColorPickerContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  left: -5px;
`;
