import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetLabelsQuery } from "./core";
import { RootState } from "./store";
import { setLabels } from "./core";
import { useEffect } from "react";
import { Navigation, NavigationProfile } from "./components";

export function Main() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const [getLabels] = useLazyGetLabelsQuery();

  useEffect(() => {
    async function fetchLabels() {
      if (user) {
        const labels = await getLabels(user?.uuid).unwrap();
        dispatch(setLabels(labels));
      }
    }

    fetchLabels();
  }, []);

  return (
    <div className="drawer drawer-open">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content p-16">
        <Outlet />
      </div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <Navigation />
        <NavigationProfile />
      </div>
    </div>
  );
}
