import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store";
import { api, setToken, setUser } from "../core";

export function NavigationProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className="absolute bottom-0 w-full p-8">
      <h2 className="pb-4 text-center">Hi {user ? user.name : ""}</h2>
      <div className="avatar w-full pb-4">
        <div className="w-8 rounded-full mx-auto ring ring-primary ring-offset-base-100 ring-offset-2">
          <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
        </div>
      </div>
      <button
        className="btn btn-primary w-full mb-4"
        onClick={() => {
          navigate("/profile");
        }}
      >
        My Profile
      </button>
      <button
        className="btn btn-primary w-full"
        onClick={() => {
          dispatch(setUser(null));
          dispatch(setToken(""));
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          dispatch(api.util.resetApiState());
        }}
      >
        Log Out
      </button>
    </div>
  );
}
