import { createBrowserRouter } from "react-router-dom";
import { Main } from "./main";
import { SignIn } from "./auth/signIn";
import { Tasks } from "./tasks/main";
import { MyProfile } from "./profile/main";
import { Protected } from "./components/protected";
import { EditTask } from "./tasks/edit-task";
import { GoogleAuth } from "./auth/googleAuth";
import { Habits } from "./habits/main";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Protected>
        <Main />
      </Protected>
    ),
    children: [
      {
        path: "/tasks",
        element: <Tasks />,
        children: [
          {
            path: ":uuid",
            element: <EditTask />,
          },
        ],
      },
      {
        path: "/habits",
        element: <Habits />,
      },
      {
        path: "/profile",
        element: <MyProfile />,
      },
    ],
  },
  {
    path: "/auth/signin",
    element: <SignIn />,
  },
  {
    path: "/auth/google/callback",
    element: <GoogleAuth />,
  },
]);
