import { api } from "./api";

export type Label = {
  name: string;
  color: string;
  uuid?: string;
};

const labelsSlice = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLabels: builder.query<Label[], string>({
      query: (id) => `/users/${id}/labels`,
      providesTags: ["labelsList"],
    }),
    addLabels: builder.mutation<void, { id?: string; label: Label }>({
      query: ({ id, label }) => ({
        url: `/users/${id}/labels`,
        body: label,
        method: "POST",
      }),
      invalidatesTags: ["labelsList"],
    }),
    updateLabel: builder.mutation<void, { id?: string; label: Label }>({
      query: ({ id, label }) => ({
        url: `/users/${id}/labels/${label.uuid}`,
        body: label,
        method: "PUT",
      }),
      invalidatesTags: ["labelsList"],
    }),
    removeLabels: builder.mutation<void, { id?: string; label?: string }>({
      query: ({ id, label }) => ({
        url: `/users/${id}/labels/${label}`,
        method: "DELETE",
      }),
      invalidatesTags: ["labelsList"],
    }),
  }),
});

export const {
  useLazyGetLabelsQuery,
  useAddLabelsMutation,
  useUpdateLabelMutation,
  useRemoveLabelsMutation,
  endpoints,
} = labelsSlice;
